import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const SitegroundReview = () => (
  <Layout>
    <SEO
      title="SiteGround Review"
      description="Presenting my things to look for in the perfect web host and my review of SiteGround after 1 year of use. Should you choose SiteGround for your website?"
    />
    <h1>SiteGround Review</h1>
      <p>UPDATE - I am in the process of moving from SiteGround to Amazon
      S3. SiteGround served it's purpose when I started blogging for it's easy
      wordpess installation. Now that I am capable in web development, Amazon S3
      suits my needs far better. I am no longer affiliated with SiteGround. I
      mention it in my article but please we wary of the price hike after the
      initial period.</p>
    <p>
      At the time of writing this - If you are looking for a cheap web host for
      a single wordpress site, I would recommend <a
        href="https://www.jdoqocy.com/click-9160314-14061248"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      namecheap EasyWP
      </a>. For multiple
      sites, it would be between SiteGround or Namecheap on a higher tier.
    </p>
    <p>
      Here’s why I chose SiteGround as my web host and what I think of it after
      1 year of use. I hope it helps you in making your decision on if you
      should try site ground hosting.
    </p>
    <p>
      When I decided to create a website, I had the following requirements. I
      wanted some of these more than others but if I could have them all it
      would be perfect. The overall goal was to have a web host that could
      support my data science & web development business and portfolio.
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>
    <ul>
      <li>A web host that can support WordPress</li>
    </ul>
    <p>
      This is because I like how easy it is to run a blog with WordPress. It
      saves me time in development and lets me focus on other parts of the job.
      Read about{" "}
      <Link to="/why-use-wordpress/" style={{ textDecoration: "none" }}>
        why I think web developers should use WordPress
      </Link>
      .
    </p>
    <ul>
      <li>A web host that is a reasonable price</li>
    </ul>
    <p>
      This is important because I didn’t want to spend a lot on a host I didn’t
      know much about. Introductory offers are good because they will let me
      decide if I want to keep using the service.
    </p>
    <ul>
      <li>
        A web host that can support my own HTML, JavaScript and CSS files easily
      </li>
    </ul>
    <p>
      This should be standard for a web host but I still wanted to make sure
      that supporting and managing my files is easy. Sometimes things you expect
      as standard aren’t always offered but it is best to check. The use of
      CPanel would help.
    </p>
    <ul>
      <li>A web host that will let me have my own custom domain name</li>
    </ul>
    <p>
      This one is obvious but I wanted to have a custom domain name. I think
      this is important for creating a brand and having a professional image.
    </p>
    <h2>A Note on Free Web Hosting</h2>
    <p>
      There are free options such as{" "}
      <a
        href="https://www.netlify.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Netlify
      </a>{" "}
      and{" "}
      <a
        href="https://pages.github.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        GitHub Pages
      </a>{" "}
      but they require you to have programming knowledge, you have to be able to
      build your entire site. As you grow and require more features, they offer
      paid plans to meet your requirements.
    </p>
    <p>
      Any service that allows WordPress installation and lets you use your own
      custom domain name will require payment. These features are expensive to
      maintain and no company is willing to give these away for free.
    </p>
    <p>
      If you ever see a service like this ask around on Reddit, Quora or any
      other community you are comfortable with before you sign up. You will
      probably find that it is a scam.
    </p>
    <p>
      As you can tell, I eventually decided to go with SiteGround, all of my
      sites are hosted on their servers. I like them for many reasons and I am
      happy with their services. It’s not all perfect because there are a few
      things I don’t like. Let’s look at what I think of SiteGround after 1 year
      of hosting.
    </p>
    <h2>SiteGround Grow Big Hosting Plan</h2>
    <p>
      I won’t go over all the technical details. You can read about them on the
      SiteGround site.
    </p>
    <p>
      If you want to compare the plans in a table look out for the very useful
      ‘Compare Hosting Plans’ link just under the plans. It makes it easy to see
      what you would get for each.
    </p>
    <p>
      Grow big. Middle of the range, as of writing the price is £4.95. Check the
      site for current pricing.
    </p>
    <h2>SiteGround Pricing</h2>
    <p>
      Let’s get the price out of the way first. A lot of reviews will quickly go
      over this point but I want to make you guys fully aware so you don’t get a
      surprise in case you miss it….
    </p>
    <p>*****</p>
    <p>
      The downside to SiteGround is that the price goes up after the
      introductory offer. For example, right now for example; if you buy hosting
      on the StartUp plan for 1 year at £2.95/mo it will go up to £8.95/mo in
      the second year. You also have to pay the entire year at once.
    </p>
    <p>
      This was for me the biggest obstacle. I eventually got over it because as
      you know I run a profitable business through this site. It is important
      for getting clients so I see it as a business cost. I haven’t tried all
      hosting services but based on research this is the best that offers
      WordPress hosting. I offset the cost of hosting against all the benefits I
      get.
    </p>
    <p>*****</p>
    <p>
      Again, if you are going to buy hosting from any provider, it is best to
      make sure you benefit in some way. The benefit can be direct profit or
      profit through attracting clients like myself and most web developers.
    </p>
    <p>
      One way to push the cost of the price increase into the future is to buy
      as many years as you can afford. I bought 2 years but knowing what I know
      now, I would have gone for more. If you are starting out, I would advise
      you go for at least 2 - 3 years when you sign up to make the most of the
      introductory offer.
    </p>
    <h2>Storage and Speed</h2>
    <p>
      The plan gives you unlimited websites, yes you read that correctly. You
      can host as many sites as you want with this plan. This plan also comes
      with 20GB storage and 25,000 visits monthly. This is more than enough for
      what I do, you will have to decide if it is enough for you.
    </p>
    <p>
      When I was doing research for SiteGround, one of the benefits that kept
      coming up was site speed. After getting my sites up and running and
      testing this, all the reviews were true. This is a big one for me because
      SEO is very important. You can have an amazing site but if your site is
      slow, you will get penalised. Google will rank you lower for having a slow
      site. Also, when users actually do click your links they could end up
      leaving before the site has loaded.
    </p>
    <p>
      One thing I have noticed is that the speed of the admin side is very slow
      in places. My actual site is really fast but when I want to work on the
      files, I can feel a noticeable delay. I see it most during things like
      logging in and clicking through tabs in my account.
    </p>
    <p>
      Despite what I said about the speed of the admin side, the user area has
      all the tools I need. Uploading and managing files is easy through CPanel
      and installing WordPress can be done in a few clicks.
    </p>
    <h2>Website Security</h2>
    <p>
      SiteGround offers SSL and HTTPS. This is all about to do with website
      security. Having the HTTPS prefix on your site lets users know that your
      site is secure. This gives users much needed confidence when we are
      browsing the internet. The good news is that SiteGround offers these for
      free and they are very easy to set up.
    </p>
    <p>
      SiteGround also has 24 hour support, luckily I haven’t had to use this
      yet. I was curious to see if this was true so I asked a general question
      about renewal. All the reviews were true because the helper answered
      within 2 minutes of me asking the question.
    </p>
    <h2>Website Backups</h2>
    <p>
      Note that this hosting plan offers a free website backup. It is very
      important to backup your site because anything can happen and it can be
      very frustrating to lose your site. Luckily it hasn’t happened to me and
      hopefully it doesn’t happen to you. Just remember that no matter how much
      you trust service providers, it is very important to back up your site
      yourself.
    </p>
    <h2>Should You Choose SiteGround?</h2>
    <p>
      When it comes to choosing a web host, I suggest looking at your priorities
      and making sure they align with what the host offers. Try not to
      compromise because when you decide on a host, you should remain committed
      to avoid unnecessary fees.
    </p>
    <p>
      Just to point out, you do get a 30 days money back guarantee if you are
      not happy with SiteGround’s service.
    </p>
    <p>
      If you want a host that is quick, offers great support and offers all the
      tools you need to get your site up and running then SiteGround is a great
      choice. I went for the middle tier GrowBig WordPress plan over the StartUp
      plan mainly because I wanted to host multiple sites. Follow the link to
      the{" "}
      <a
        href="https://www.siteground.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        SiteGround website
      </a>{" "}
      to learn more. Don’t forget to look at that comparison table I mentioned
      earlier that lets you compare plans.
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default SitegroundReview
